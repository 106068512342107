/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import App from 'next/app';
import { UserContextProvider } from '../components/UserContext';
import 'pretty-checkbox';
import './global.css';


class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    require('jquery');
    require('popper.js');
    require('bootstrap');
  }

  render() {
    const { Component, pageProps } = this.props;
    pageProps.url = this.state.url;
    return (
      <UserContextProvider>
        <Head>
          <title>PPPays</title>
          <link rel="preconnect stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=block" />
          <link rel="preconnect stylesheet" href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap" />
          <script dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '630454467595743');
  fbq('track', 'PageView');`,
          }}
          />
          <noscript dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=630454467595743&ev=PageView&noscript=1"
/>`,
          }}
          />

          <script dangerouslySetInnerHTML={{
            __html: `
            _linkedin_partner_id = "2200938";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            `,
          }}
          />

          <script dangerouslySetInnerHTML={{
            __html: `
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();
            `,
          }}
          />

          <noscript dangerouslySetInnerHTML={{
            __html: `
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2200938&fmt=gif" />
            `,
          }}
          />

          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-617624061" />
          <script dangerouslySetInnerHTML={{ __html: 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'AW-617624061\');' }} />


          {process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && (
          <script
            async
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
              window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
              heap.load("${process.env.HEAP_ID}");
            `,
            }}
          />
          )}
        </Head>
        <Component {...pageProps} />
      </UserContextProvider>
    );
  }
}

export default MyApp;
